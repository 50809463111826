import React from 'react';
import axios from 'axios';

import logoBB from './static/i/logo_main.png';

export default function KaraokeSetup() {

    const [joinCode, setJoinCode] = React.useState(null);
    const [accessCode, setAccessCode] = React.useState(null);
    const [error, setError] = React.useState(null);

    const updateCode = React.useCallback(() => {
        if (!joinCode && !accessCode) { return; }

        const saveData = async () => {
            try {
                await axios.post('/api/save-karaoke', { accessCode: accessCode, code: joinCode });
                window.location.href = `/karaoke`;
            } catch (error) {
                setError(error.response.data.message);
            }
        };
        saveData();
    }, [joinCode, accessCode])

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logoBB} alt="Main logo" width={300} />
                <br />
                <div className="form">
                    <div>
                        <label>Set the Karaoke Session</label>
                        <input type="text" value={joinCode} onChange={(e) => setJoinCode(e.target.value)} placeholder='Enter the Singing Machine join code' />
                    </div>
                    <div>
                        <label>Set the Access Code (Numbers only)</label>
                        <input type="text" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} placeholder='Enter an Access code' />
                    </div>
                </div>
                <br />
                <div class="btn-bar">
                    <button className="btn" onClick={() => updateCode()}>Set</button>
                    <button className="btn" onClick={() => window.location.href = '/'}>Cancel</button>
                </div>
                <br/>
                {error ? <><p>{error}</p></> : null}
            </div>
        </div>
    </div>;
}
import React from 'react';
import axios from 'axios';
import logo from './static/i/singingmachine.png';
import logoBB from './static/i/logo_main.png';

export default function Karaoke() {

    const [accessCode, setAccessCode] = React.useState(null);
    const [trySubmit, setTrySubmit] = React.useState(false);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (!trySubmit || !accessCode) return;
        const checkAccessCode = async () => {
            try {
                const response = await axios.post('/api/validate-karaoke', { accessCode: accessCode });
                const jsonData = await response.data
                setTimeout(() => {
                    window.location.href = `https://karaoke-web-companion-prod.stingray.com/en/join?id=${jsonData.code}`;
                }, 3000);
            } catch (error) {
                if (error.status === 401) { setError('Invalid access code'); return; }
                if (error.status === 500) { setError('Failed to validate access code'); return; }
            }
        };
        checkAccessCode();
    }, [accessCode, trySubmit]);

    return <div className="page">
        <div className="page-center">
            <div className='redirect'>
                <img src={logoBB} alt="Main logo" width={300} />
                <br />
                <h2>Join the Karaoke Session</h2>
                <img src={logo} alt="Singing Machine" width={200} />
                <br />

                {!trySubmit ?
                    <div className="form">
                        <div>
                            <div>
                                <label>Access Code</label>
                                <input type="text" value={accessCode || accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                            </div>
                            <button className="btn" onClick={() => setTrySubmit(true)}>Join</button>
                        </div>
                    </div>
                    :
                    <>
                        {error ? <><p>{error}</p><br /><a href="/karaoke">Try again</a></> : <p>Validating access code...</p>}
                    </>
                }
            </div>
        </div>
    </div>;
}